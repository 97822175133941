.nav-logo:hover {
  cursor: pointer;
}

.navbar-wrapper {
  margin-top: 1%;
  position: sticky;
  top: 0;
  z-index: 9999;
  background-color: white;
  opacity: 0.95;

  @media screen and (max-width: 576px) {
    height: 10%;
  }
}

.navbar-toggler {
  border: none;
  color: black;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='29' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
}

.nav-link {
  padding-left: 30px !important; 
  font-size: 1.2em;
  text-align: center;
  color: black;
  font-weight: 500;
  margin-top: 4px;
  

  &:hover {
      color: black;
  }
}

.nav-text {
  font-family: 'Inter', sans-serif !important;
}