@import url('https://fonts.googleapis.com/css2?family=Inter:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@600&display=swap');

.contact-section {
  margin-bottom: 35%;
}

.contact-item {
  display: inline;
  text-align: center;
  margin-right: 2.5rem;
  margin-left: 2.5rem;
  color: #333;
  font-size: 3em;
  list-style: none;
  
  @media screen and (max-width: 576px) {
  font-size: 1.5em;
  }

}

.contact-item:hover {
  color: black;
  cursor: pointer;
}