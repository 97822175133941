@import url('https://fonts.googleapis.com/css2?family=Inter:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@600&display=swap');

.App {
  text-align: left;
}

:root {
  --off-white: #fafafa;
}

h1 {
  font-family: 'Inter', sans-serif;
  font-size: 5.5em;

  @media screen and (max-width: 576px) {
    font-size: 2em;
  }
}

h2 {
  font-family: 'Inter', sans-serif;
  font-size: 4em;

  @media screen and (max-width: 576px) {
    font-size: 1.5em;
  }
}

h4 {
  font-family: 'Inter', sans-serif;
  font-size:1.5em;
  color: grey;

  @media screen and (max-width: 576px) {
    font-size: 1em;
  }
}

p.home-context {
  font-family: 'Inter', sans-serif;
  font-size: 1.5em;
  color: grey;

  @media screen and (max-width: 576px) {
    font-size: 0.8em;
  }
}

p.projects-context {
  font-family: 'Inter', sans-serif;
  font-size: 1em;
  color: grey;

  @media screen and (max-width: 576px) {
    font-size: 0.8em;
  }
}

p.contact-context {
  font-family: 'Inter', sans-serif;
  font-size: 1.5em;
  color: grey;

  @media screen and (max-width: 576px) {
    font-size: 0.8em;
  }
}