.home-section {
  margin-top: 10%;
  margin-bottom: 5%;

}

.scrollProject {
  position: relative;
  width: 8em;
  height: 2.5em;
  background-color: #333;
  font-size: 1em;
  color: white;
  text-align: center;
  border: 2px solid black;
  @media screen and (max-width: 576px) {
    width: 7em;
    height: 2em;
    font-size: 0.8em;
  }

}

.scrollProject:hover {
  background-color: black;
  color: white;
  cursor: pointer;
}

.wave {
  animation-name: wave-animation;  /* Refers to the name of your @keyframes element below */
  animation-duration: 2.5s;        /* Change to speed up or slow down */
  animation-iteration-count: infinite;  /* Never stop waving :) */
  transform-origin: 70% 70%;       /* Pivot around the bottom-left palm */
  display: inline-block;
}

@keyframes wave-animation {
    0% { transform: rotate( 0.0deg) }
   10% { transform: rotate(14.0deg) }  /* The following five values can be played with to make the waving more or less extreme */
   20% { transform: rotate(-8.0deg) }
   30% { transform: rotate(14.0deg) }
   40% { transform: rotate(-4.0deg) }
   50% { transform: rotate(10.0deg) }
   60% { transform: rotate( 0.0deg) }  /* Reset for the last half to pause */
  100% { transform: rotate( 0.0deg) }
}
