@import url(https://fonts.googleapis.com/css2?family=Inter:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@600&display=swap);
body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App{text-align:left}:root{--off-white: #fafafa}h1{font-family:'Inter', sans-serif;font-size:5.5em}@media screen and (max-width: 576px){h1{font-size:2em}}h2{font-family:'Inter', sans-serif;font-size:4em}@media screen and (max-width: 576px){h2{font-size:1.5em}}h4{font-family:'Inter', sans-serif;font-size:1.5em;color:grey}@media screen and (max-width: 576px){h4{font-size:1em}}p.home-context{font-family:'Inter', sans-serif;font-size:1.5em;color:grey}@media screen and (max-width: 576px){p.home-context{font-size:0.8em}}p.projects-context{font-family:'Inter', sans-serif;font-size:1em;color:grey}@media screen and (max-width: 576px){p.projects-context{font-size:0.8em}}p.contact-context{font-family:'Inter', sans-serif;font-size:1.5em;color:grey}@media screen and (max-width: 576px){p.contact-context{font-size:0.8em}}

.nav-logo:hover{cursor:pointer}.navbar-wrapper{margin-top:1%;position:-webkit-sticky;position:sticky;top:0;z-index:9999;background-color:white;opacity:0.95}@media screen and (max-width: 576px){.navbar-wrapper{height:10%}}.navbar-toggler{border:none;color:black}.navbar-toggler-icon{background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='29' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important}.nav-link{padding-left:30px !important;font-size:1.2em;text-align:center;color:black;font-weight:500;margin-top:4px}.nav-link:hover{color:black}.nav-text{font-family:'Inter', sans-serif !important}

.home-section{margin-top:10%;margin-bottom:5%}.scrollProject{position:relative;width:8em;height:2.5em;background-color:#333;font-size:1em;color:white;text-align:center;border:2px solid black}@media screen and (max-width: 576px){.scrollProject{width:7em;height:2em;font-size:0.8em}}.scrollProject:hover{background-color:black;color:white;cursor:pointer}.wave{animation-name:wave-animation;animation-duration:2.5s;animation-iteration-count:infinite;transform-origin:70% 70%;display:inline-block}@keyframes wave-animation{0%{transform:rotate(0deg)}10%{transform:rotate(14deg)}20%{transform:rotate(-8deg)}30%{transform:rotate(14deg)}40%{transform:rotate(-4deg)}50%{transform:rotate(10deg)}60%{transform:rotate(0deg)}100%{transform:rotate(0deg)}}

.projects-section{margin-bottom:40%}.project-space{margin-top:20%}.scrollContact{position:relative;width:8em;height:2.5em;background-color:#333;font-size:1em;color:white;text-align:center;border:2px solid black}@media screen and (max-width: 576px){.scrollContact{width:7em;height:2em;font-size:0.8em}}.scrollContact:hover{background-color:black;color:white;cursor:pointer}.projectLink{display:inline;text-align:center;color:#333;font-size:2em;list-style:none}@media screen and (max-width: 576px){.projectLink{font-size:1.5em}}.projectLink:hover{color:black;cursor:pointer}

.contact-section{margin-bottom:35%}.contact-item{display:inline;text-align:center;margin-right:2.5rem;margin-left:2.5rem;color:#333;font-size:3em;list-style:none}@media screen and (max-width: 576px){.contact-item{font-size:1.5em}}.contact-item:hover{color:black;cursor:pointer}

